.App {
  padding: 12px;
  max-width: 480px;
  margin: 0 auto;
}

.App-logo {
  height: 13vmin;
  pointer-events: none;
}


.App-header {
  margin-bottom: 4vmin;
}